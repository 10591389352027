export const API_ROOT = 
'https://prettau-sy.com/Admin/api'
;
export const ROOT = 
'https://prettau-sy.com/Admin/'
;

// export const API_ROOT = 
// 'http://localhost/Prettau-Dental/Backend/api'
// ;
// export const ROOT = 
// 'http://localhost/Prettau-Dental/Backend/'
// ;