
import { t } from "i18next";

function Information(){


    return(
        <section className="information">
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="100ms">
                        <div className="information__single">
                            <div className="information__icon">
                                <span className="icon-chat-1"></span>
                            </div>
                            <p className="information__text">{t('contact-working-time')}</p>
                            <p className="information__number">24/7</p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="300ms">
                        <div className="information__single">
                            <div className="information__icon">
                                <span className="icon-gmail"></span>
                            </div>
                            <p className="information__text">{t('contact-mail-us')}</p>
                            <p className="information__number">
                                <a dir="ltr" href="mailto:Prettau.prettau@gmail.com">Prettau.prettau@gmail.com</a>
                            </p>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                        <div className="information__single">
                            <div className="information__icon">
                                <span className="icon-location-2"></span>
                            </div>
                            <p className="information__text">{t('contact-address')} </p>
                            <p className="information__number">{t('location_dubai')} </p>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                        <div className="information__single">
                            <div className="information__icon">
                                <span className="icon-location-2"></span>
                            </div>
                            <p className="information__text">{t('contact-address')} </p>
                            <p className="information__number">{t('location')}</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 wow fadeInUp" data-wow-delay="200ms">
                        <div className="information__single">
                            <div className="information__icon">
                                <span className="icon-phone-call"></span>
                            </div>
                            <p className="information__text">{t('contact-call-us')}</p>

                            {/* Responsive Phone Number Grid */}
                            <div className="information__grid">
                                <div className="information__grid-item">
                                    <p className="information__text mt-0">{t('uae-dubai')}</p>
                                    <p className="information__number">
                                        <a dir="ltr" href="tel:+971509197772">+971 (509) 197-772</a>
                                    </p>
                                </div>
                                <div className="information__grid-item">
                                    <p className="information__text mt-0">{t('iraq-sulaymaniyah')}</p>
                                    <p className="information__number">
                                        <a dir="ltr" href="tel:+9647726969090">+964 (772) 696-9090</a>
                                    </p>
                                </div>
                                <div className="information__grid-item">
                                    <p className="information__text mt-0">{t('iraq-sulaymaniyah')}</p>
                                    <p className="information__number">
                                        <a dir="ltr" href="tel:+9647736985841">+964 (773) 698-5841</a>
                                    </p>
                                </div>
                                <div className="information__grid-item">
                                    <p className="information__text mt-0">{t('sweden')}</p>
                                    <p className="information__number">
                                        <a dir="ltr" href="tel:+46700555488">+467 (005) 55-488</a>
                                    </p>
                                </div>
                                <div className="information__grid-item">
                                    <p className="information__text mt-0">{t('netherlands')}</p>
                                    <p className="information__number">
                                        <a dir="ltr" href="tel:+31617342717">+316 (173) 427-17</a>
                                    </p>
                                </div>
                                <div className="information__grid-item">
                                    <p className="information__text mt-0">{t('belgium')}</p>
                                    <p className="information__number">
                                        <a dir="ltr" href="tel:+32465287124">+324 (652) 871-24</a>
                                    </p>
                                </div>
                                <div className="information__grid-item">
                                    <p className="information__text mt-0">{t('united-kingdom')}</p>
                                    <p className="information__number">
                                        <a dir="ltr" href="tel:+447887101300">+447 (887) 101-300</a>
                                    </p>
                                </div>
                                <div className="information__grid-item">
                                    <p className="information__text mt-0">{t('germany')}</p>
                                    <p className="information__number">
                                        <a dir="ltr" href="tel:+4915210607380">+491 (521) 060-7380</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )}

export default Information;